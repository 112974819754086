import { alpha, FormControl, FormControlLabel, Grid, IconButton, makeStyles, RadioGroup, Typography } from "@material-ui/core";
import React, {forwardRef,useEffect, useImperativeHandle, useRef, useState,} from "react";
import { useForm, Controller } from "react-hook-form";
import { ArrowIcon } from "../../components/IconsLibrary/ArrowIcon";
import ControlledInputRoundedForm from "../../components/InputForm/ControlledInputRoundedForm";
import ControlledSelect from "../../components/InputForm/ControlledSelect";
import CustomModal from "../../components/Modal/modalLogin";
import { ControlledSwitchComponent } from "../../components/Switch/controlledSwitchForm.component";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "../../utils/proprietaryHooks";
import { SelectIcon } from "./selectIcon";
import { dataIcons } from "./dataIcons";
import { getList } from '@dg-bucaramanga/react-components-dg-pre'
import { PrimaryButton } from "../../components/ButtonForm/PrimaryButton";
import StyledRadioButton from "../../components/ButtonForm/StyledRadioButton.component";
import { ControlledAutocompleteInputRoundedForm } from "../../components/InputForm/ControlledAutocompleteInputRoundedForm";

export const ModalForm = forwardRef((props, ref) => {
  const {
    section,
    initialValues,
    open,
    setOpen,
    handleSave,
    handleClose,
    privileges,
  } = props;

  const classes = useStyles();
  const iconsTotal = useRef([...getList(), ...dataIcons])
  const [selectedImageLogo, setSelectedImageLogo] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [hasUserInteracted, setHasUserInteracted] = useState(false);

  const [header, setHeader] = useState({
    tab0: {
      header: "Nuevo ítem",
      subtitle: "Ingresa los datos del ítem",
    },
    tab1: {
      header: "Nuevo ítem",
      subtitle: "Selecciona un ícono para el ítem",
    },
  });


  const requiredMessage = "Campo obligatorio";
  const schema = yup.object().shape({
    name: yup.string().required(requiredMessage),
    privilege: yup.string().required(requiredMessage),
    section: yup.string().required(requiredMessage),
    isFunction: yup.boolean().required(requiredMessage),
    functionName: yup.lazy((value) => {
      return value.isFunction
        ? yup.string()
        : yup.string().required(requiredMessage);
    }),
    sizeIcon: yup.number(),
    url: yup.lazy((value) => {
      return !value.isFunction
        ? yup
            .string()
            .matches(
              /^(http[s]?):\/\/[a-zA-Z0-9-]{2,}(\.|:)[a-zA-Z0-9/.#?=&%-]+(:[a-zA-Z0-9/.#?=&%-]+)?$/,
              "Ingrese una url correcta!"
            )
            .required(requiredMessage)
        : yup.string();
    }),
  });
  const form = useForm({
    defaultValues: initialValues,
    mode: "onChange",
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });
  const {
    control,
    errors,
    trigger,
    getValues,
    handleSubmit,
    formState,
    setValue,
    watch,
    clearErrors
  } = form;

  /**
   * set the values to inputs when view is triggered
   */
  useEffect(() => {
    if (initialValues.name !== "") {
      Object.keys(initialValues).forEach((key) => {
        if (key === "privilege") {
          setValue("privilege", initialValues[key]?.toString());
        } else {
          if(key !== "title"){
            if(key !== "children"){
              setValue(key, initialValues[key]);
            }
          }
        }
        if (key === "isFunction") {
          setValue("isFunction", initialValues[key] === true ? "funcion": "url");
        }
      });
      setHeader({
        tab0: {
          header: `Edición- ${initialValues["name"]}`,
          subtitle: "Modifica los datos del ítem",
        },
        tab1: {
          header: `Edición- ${initialValues["name"]}`,
          subtitle: "Selecciona un ícono para el ítem",
        },
      });
      setSelectedImageLogo({ icon: initialValues["iconName"] });
    } else {
      setHeader({
        tab0: {
          header: "Nuevo ítem",
          subtitle: "Ingresa los datos del ítem",
        },
        tab1: {
          header: "Nuevo ítem",
          subtitle: "Selecciona un ícono para el ítem",
        },
      });
      Object.keys(initialValues).forEach((key) => {
        if(key !== "title"){
          setValue(key, initialValues[key]);
        setValue(key, initialValues[key]);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, setValue]);

  useEffect(() => {
    if (isFirstLoad) {
      setValue("isFunction", "url");
      setIsFirstLoad(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstLoad]);

  // deprecated
  const handleCloseModal = (save, nameIcon) => {
    if (!save) {
      setSelectedImageLogo((state) => ({ ...state, icon: "" }));
    } else {
      setSelectedImageLogo((state) => ({ ...state, icon: nameIcon }));
      // clear errors
    }
    setOpen(false);
  };

  const handleClickModal = () => {
    const elements = document.getElementsByClassName("selectedIcon");
    let iconName = "";
    let isPersonalizedIcon = false;
    if (elements.length > 0) {
      for (const element of elements) {
        iconName = element.getAttribute("iconname");
        isPersonalizedIcon = element.getAttribute("isPersonalizedIcon");
      }
    }
    const isEdit = initialValues.name !== "" ? "Edit" : undefined;
    const itemValues = getValues();
    itemValues.isFunction = typeof itemValues.isFunction === "boolean" ? itemValues.isFunction : itemValues.isFunction === "funcion" ? true : false;
    handleSave(iconName, isPersonalizedIcon === "true", isEdit, itemValues);
  };

  const handleChangeTab = async () => {
    await trigger();
    if (!isEmpty(formState.errors)) {
      return;
    } else {
      setCurrentTab(1);
    }
  };
  
  const handleChangeRadio = (evt) => {
    const { value } = evt.target;
    const isUrlSelected = value === "url";

    if (!hasUserInteracted) {
      setHasUserInteracted(true);
    }

    setValue("url", isUrlSelected ? "" : "");
    setValue("funcion", isUrlSelected ? "" : "funcion");
    setValue("isFunction", value);
  };

  /**
   * make body of modal depending tab
   * @returns {JSX.Element} body
   */
  const getBody = () => {
    let modal = {};
    if (currentTab === 0) {
      modal = {
        header: (
          <Typography
            variant="h6"
            color="primary"
            className={classes.modalHeader}
          >
            {header.tab0.header}
          </Typography>
        ),
        body: (
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                className={classes.bodyTitle}
              >
                {header.tab0.subtitle}
              </Typography>
            </Grid>
            {section ? (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Controller
                  name="section"
                  control={control}
                  render={()=>
                    <ControlledSelect
                      id="section"
                      label="Sección del menú"
                      name="section"
                      control={control}
                      options={section}
                      error={errors.section}
                      helperText={errors.section?.message}
                    />
                  }
                />
              </Grid>
            ) : null}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ControlledInputRoundedForm
                id="name"
                fullWidth
                control={control}
                name="name"
                label="Nombre elemento"
                error={errors.name}
                helperText={errors.name?.message}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
            <Controller
                name="privilege"
                control={control}
                render={() => (
                  <ControlledAutocompleteInputRoundedForm
                    id="privilege"
                    name="privilege"
                    data={privileges}
                    watch={watch}
                    setValue={setValue}
                    error={errors.privilege}
                    helperText={errors.privilege?.message}
                    clearErrors={clearErrors}
                  />
              )}
              />
            </Grid>
            <Grid
              container
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              alignItems="center"
            >
              <Typography margin="dense"  >
              </Typography>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                      row
                      aria-label="Tipo de vinculo"
                      value={watch('isFunction') || "url"}
                      onChange={handleChangeRadio}>
                      <FormControlLabel
                        name="isFunction"
                        InputRef={control}
                        value="url"
                        control={<StyledRadioButton />}
                        label="URL"
                        error={errors.isFunction}
                        helperText={errors.isFunction?.message}
                      />
                      <FormControlLabel
                        name="isFunction"
                        InputRef={control}
                        value="funcion"
                        control={<StyledRadioButton />}
                        label="Función"
                        error={errors.isFunction}
                        helperText={errors.isFunction?.message}
                      />
                    </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            {
              watch("isFunction") === "funcion" || watch("isFunction") === true ? (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <ControlledInputRoundedForm
                    id="functionName"
                    fullWidth
                    control={control}
                    name="functionName"
                    label="Nombre de la función"
                    error={errors.functionName}
                    helperText={errors.functionName?.message}
                  />
                </Grid>
              ) : (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <ControlledInputRoundedForm
                    id="url"
                    fullWidth
                    control={control}
                    name="url"
                    label="Url"
                    error={errors.url}
                    helperText={errors.url?.message}
                  />
                </Grid>
              )
            }
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ControlledInputRoundedForm
                id="description"
                fullWidth
                control={control}
                name="description"
                multiline
                rows={5}
                label="Descripción (opcional)"
                error={errors.description}
                helperText={errors.description?.message}
              />
            </Grid>
            <Grid
              container
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              alignItems="center"
            >
              <Grid item lg={2} md={2} xs={3} sm={3}>
                <Typography>Activo</Typography>
              </Grid>
              <Grid item lg={3} md={3} xs={3} sm={3}>
                <ControlledSwitchComponent
                  name="isActivate"
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
        ),
        actions: (
          <Grid container direction="row-reverse">
            <Grid container item lg={4} md={4} sm={6} xs={6}>
              <Grid
                container
                item
                lg={9}
                md={9}
                sm={6}
                xs={6}
                alignItems="center"
              >
                <Typography color="secondary">Siguiente</Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={6}>
                <IconButton
                  area-label="NextTab"
                  onClick={() => handleChangeTab()}
                >
                  <ArrowIcon className={classes.nextArrow} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ),
      };
    } else {
      modal = {
        header: (
          <Typography
            variant="h6"
            color="primary"
            className={classes.modalHeader}
          >
            {header.tab1.header}
          </Typography>
        ),
        body: (
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div
                className={classes.paperSelectIcon}
                style={{ paddingTop: "10px", marginBottom: 18 }}
              >
                <SelectIcon
                  selectedIcon={selectedImageLogo}
                  dataIcons={iconsTotal.current}
                  handleCloseModal={handleCloseModal}
                  title={header.tab1.subtitle}
                  setSelectedIcon={setSelectedImageLogo}
                />
              </div>
            </Grid>
          </Grid>
        ),
        actions: (
          <Grid container item lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <IconButton
                area-label="PreviousTab"
                onClick={() => setCurrentTab(0)}
              >
                <ArrowIcon className={classes.previousArrow} />
              </IconButton>
            </Grid>
            <Grid container item lg={6} md={6} sm={6} xs={6} justifyContent="flex-end">
              <PrimaryButton
                text={
                  initialValues.name !== "" ? "Actualizar ítem" : "Crear item"
                }
                onClick={handleClickModal}
              />
            </Grid>
          </Grid>
        ),
      };
    }
    return modal;
  };

  const restartModal = () => {
    setCurrentTab(0);
  };

  useImperativeHandle(
    ref,
    () => ({
      form: form,
      logo: selectedImageLogo,
      save: handleClickModal,
      restartModal,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <CustomModal
      open={open}
      handleClose={() => {
        handleClose();
        clearErrors();
        setOpen(false);
      }}
      body={
        <div>
          {getBody().header}
          {getBody().body}
        </div>
      }
      actions={getBody().actions}
      width={"503px"}
      handleSubmit={handleSubmit(handleChangeTab)}
    />
  );
});

const useStyles = makeStyles((theme) => ({
  previousArrow: {
    transform: "rotate(180deg)",
    stroke: theme.palette.secondary.main,
  },
  nextArrow: {
    stroke: theme.palette.secondary.main,
  },
  modalHeader: {
    fontWeight: theme.typography.fontWeightBold,
  },
  bodyTitle: {
    color: theme.palette.primary.light,
  },
  paperSelectIcon: {
    height: "350px",
    marginBottom: 120,
  },
  closeIcon: {
    color: theme.palette.primary.light,
    fontSize: "15px",
  },
  inputHiddenLabel: {
    color: "#b2b2b2",
  },
  autoComplete: {
    root: {
      border: "1px solid #e2e2e1",
      overflow: "hidden",
      borderRadius: 10,
      backgroundColor: "#FFFFFF",
      boxShadow: `0px 1px 1px #00000026`,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:hover": {
        backgroundColor: "#fff",
      },
      "&$focused": {
        backgroundColor: "#fff",
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.primary.main,
      },
      "&$error": {
        borderColor: theme.palette.error.main,
      },
      "& .MuiInputBase-input.Mui-error": {
        borderColor: theme.palette.error.main,
      },
    },
    inputHiddenLabel: {
      color: "#b2b2b2",
    },
    focused: {
      fontSize: 14,
    },
    error: {},
  }
}));
