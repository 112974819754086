//set app name to all project
export const APP_NAME = process.env.name || "MICROSERVICES";

//token prefix eg. 'Bearer ' or 'Token '
export const TOKEN_PREFIX = "Bearer ";

//time to refresh token
export const TIME_REFRESH_TOKEN = 20;

//time to redirect login
export const TIME_REDIRECT_LOGIN = 4;

export const API_AUTHENTICATION =
  process.env.NODE_ENV === "production"
    ? "https://5sw5ah9606.execute-api.us-east-2.amazonaws.com/pre/authentication"
    : "https://5sw5ah9606.execute-api.us-east-2.amazonaws.com/pre/authentication";

export const API_SECURITY_ENDPOINT =
  process.env.NODE_ENV === "production"
    ? "https://precoresecurity.gse.com.co/security"
    : "https://precoresecurity.gse.com.co/security";

export const URL_SECURITY = "https://presecurity.gse.com.co/";

export const APP_HEADER_NAME = "appName";
export const TENANT_HEADER_NAME = "tenant";

export const defaultResponses = {
  noResponseFromApi:
    "No hay respuesta por parte del servidor. Por favor intente de nuevo más tarde.",
};

export function defaultHeaders() {
  return {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
}

export const encryptionKey = [
  227, 144, 75, 59, 108, 168, 38, 75, 193, 195, 63, 125, 144, 17, 12, 148, 217,
  49, 188, 190, 52, 189, 68, 134, 106, 170, 255, 88, 27, 183, 249, 88,
];

export const encryptionIv = [
  58, 237, 223, 159, 226, 61, 73, 88, 156, 25, 99, 11, 144, 81, 32, 101,
];

export const VERSION = "Versión 1.4.0";

export const LANGUAGES = [
  { label: "Español", code: "es", img: "/icons/ico_es.png" },
  { label: "English", code: "en", img: "/icons/ico_en.png" },
  { label: "Português", code: "pt", img: "/icons/ico_pt.png" },
];
