import { Grid } from "@material-ui/core";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { createMenu, GetMenuByApp, ModifyMenu, } from "../../../actions/menu.action";
import ControlledSelect from "../../InputForm/ControlledSelect";
import { convertPrivilegesToList, generateKeyId, updateActivateState , isEmpty } from "../../../utils/proprietaryHooks";
import { MenuSettingsContainer } from "../MenuSettingsContainer";
import { ModalForm } from "../../../views/menuSettings/ModalForm";
import { useDispatch, useSelector } from "react-redux";
import { getPrivileges } from "../../../actions/privileges.action";
import FullLoader from "../../Loader/FullLoader.component";
import { UseDialog } from "@dg-bucaramanga/react-components-dg-pre";
import { GET_MENU_CHANGE } from "../../../actions/types";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const defaultPopupState = {
  message: "",
  messageType: "",
};

export let appliCustomMenu = "";

export const CustomMenuSettings = forwardRef((props, ref) => {
  const { menuLocation, menu , onCancelButton } = props;
  const { currentMenu, setCurrentMenu, idMenu, setCurrentLeftDragDrop, setCurrentTopDragDrop } = menu;

  //#region object definition
  const [open, setOpen] = useState(false);
  const [applications, setApplications] = useState([]);

  const [messageChangeMenu, setMessageChangeMenu] = useState("");

  const form = useForm({
    shouldUnregister: false,
    defaultValues: {
      application: "",
      state: false,
    },
  });

  /**
   * Valores Iniciales del MenuJson del objeto menu
   */
  const initialValues = {
    name: "",
    privilege: "",
    url: "",
    isActivate: false,
    description: "",
    isFunction: false,
    functionName: "",
    sizeIcon: "24px",
    children: [],
  };

  const { control, watch } = form;
  const modalRef = useRef(null);
  const menuSettingRef = useRef(null);
  const getPrivilegesRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const [privilegesList, setPrivilegesList] = useState([]);
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  const [popupOpen, setPopupOpen] = useState(false);
  const [shouldUpdateMenu, setShouldUpdateMenu] = useState(false);
  const [isSaveButton , setIsSaveButton] = useState(false);
  const [isUpdatedItemFlag, setIsUpdatedItemFlag] = useState(false);

  const dispatch = useDispatch();

  const privileges = useSelector(({ privilegesReducer }) => {
    if (!privilegesReducer.getPrivilegesResponse) {
      return [];
    }
    return privilegesReducer.getPrivilegesResponse ?? [];
  });

  const menuChange = useSelector(({ menuReducer }) => {
    return menuReducer.getMenuChangeResponse;
  });

  const popup = useSelector(({ popupReducer }) => {
    return popupReducer?.popup ?? defaultPopupState;
  });

  const [currentApp, setCurrentApp] = useState({
    name: "",
    id: 0,
    items: [],
  });

  const { Dialog, onOpen } = UseDialog({
    bodyText: messageChangeMenu,
  });

  useEffect(() => {
    if (menu.currentMenu.length > 0) {
      menuSettingRef.current.addInitialDataMenu(menu.currentMenu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu.currentMenu]);

  //#endregion
  useEffect(() => {
    const fetchPrivileges = async () => {
      try {
        setLoading(true);
        await dispatch(getPrivileges(currentApp.name));
      } catch (error) {
        setPopupOpen(true);
      } finally {
        setLoading(false);
        getPrivilegesRef.current = false;
      }
    };

    if (currentApp.name !== "" && getPrivilegesRef.current) {
      fetchPrivileges();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApp]);

  useEffect(() => {
    if (!isEmpty(privileges)) {
      if (privileges.error) {
        setPopupOpen(true);
        setLoading(false);
      } else {
        setPrivilegesList(privileges);
        setLoading(false);
      }
    } else {
      setPrivilegesList(privileges);
      setLoading(false);
    }
  }, [privileges]);

  useEffect(() => {
    if (popup.message !== "") {
      setPopupOpen(true);
    }
  }, [popup]);

  useEffect(() => {
    const applicationId = watch("application");
    appliCustomMenu = applicationId;

    if (applicationId && applicationId !== "" && menuLocation && menuLocation !== "") {
      getPrivilegesRef.current = true;
      setCurrentMenu([]);

      if (menuSettingRef.current) {
        menuSettingRef.current.addInitialDataMenu([]);
      }
      setIsSaveButton(false);
      setIsUpdatedItemFlag(false);
      dispatch(GetMenuByApp(applicationId, menuLocation));
      setCurrentApp((prevState) => ({
        ...prevState,
        name: applications.find((app) => app.id === applicationId).name,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("application"), menuLocation]);

  useEffect(() => {
    if (!isEmpty(menuChange) && menuLocation && menuLocation !== 0) {
      setLoading(false);
      onOpen();
      dispatch({
        type: GET_MENU_CHANGE,
        payload: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuChange, menuLocation]);

  useEffect(() => {
    if (shouldUpdateMenu) {
      dispatch(GetMenuByApp(watch("application"), menuLocation));
      setShouldUpdateMenu(false);
    }
  }, [shouldUpdateMenu, dispatch, watch, menuLocation]);

  //#region custom methods

  /**
   * This function saves an item to the menu, and if the item is edited, it will update the menu with
   * the new values.
   */
  const saveItem = (iconName, isPersonalizedIcon, action, itemValues) => {
    setOpen(false);
    let values = {};
    if (action) {
      const menuValues = menuSettingRef.current.values.menu1;
      const searchValues = {
        edit: { ...itemValues, iconName, isPersonalizedIcon },
        state: { ...itemValues },
        delete: {},
      };
      const arrayOfObject = Object.entries(searchValues[action.toLowerCase()]).sort();
      const orderedObject = Object.fromEntries(arrayOfObject);
      const newMenu = menuSettingRef.current.searchItemUpdateAndDelete(
        itemValues.key,
        menuValues,
        action,
        orderedObject
      );
      menuSettingRef.current.addInitialDataMenu(newMenu);
      setCurrentMenu(newMenu);
    } else {
      // create item
      const { getValues } = modalRef.current.form;
      values = getValues();
      const item = {
        application: watch("application"),
        ...values,
        key: generateKeyId(10),
        menuLocation: menuLocation,
        isPersonalizedIcon,
        iconName,
        ...modalRef.current.logo,
      };
      const arrayOfObject = Object.entries(item).sort();
      const orderedObject = Object.fromEntries(arrayOfObject);
      const previousMenu = [...currentMenu, orderedObject];
      setCurrentMenu(previousMenu);
      menuSettingRef.current.addItemMenu(orderedObject);
    }
    handleClose();
    setIsUpdatedItemFlag(true);
    setIsSaveButton(true);
  };

  /**
   * Cuando el modal se cierra, restablece los valores del formulario a los valores iniciales.
   */
  const handleClose = () => {
    setInitialFormValues(initialValues);
    modalRef.current.restartModal();
  };

  /**
   * Si el parámetro useHooksData es verdadero, entonces el parámetro data se asigna a la variable valuesComplete
   , en caso contrario se asigna a la variable valuesComplete el valor de la propiedad menu1 del objeto
    objeto menuSettingRef.current
   * @param {*} useHooksData 
   * @param {*} data 
   * @param {*} message could be: save, edit or delete
   */
  const saveMenu = async (useHooksData, data, message = "¡Menú guardado exitosamente!") => {
    useHooksData = typeof useHooksData !== "boolean" ? false : useHooksData;
    let valuesComplete = useHooksData ? data : menuSettingRef.current.values.menu1;
    valuesComplete = convertPrivilegesToList(valuesComplete);
    updateActivateState(valuesComplete, null);
    valuesComplete.forEach((item) => {
      if (item.isFunction === "url") {
        item.isFunction = false;
      }
      if (item.isFunction === "funcion") {
        item.isFunction = true;
      }
    });
    if (idMenu === 0) {
      const objectCreate = {
        id: idMenu,
        idapplication: watch("application"),
        idmenulocation: menuLocation,
        numberRows: menuLocation === 4 ? 4 : 0,
        menujson: JSON.stringify(removeTitle(valuesComplete)),
      };
      try {
        await new Promise(createMenu(objectCreate));
      } catch (error) {
        console.error(error);
      }
      setMessageChangeMenu(message);
      onOpen();
      setShouldUpdateMenu(true);
    } else {
      const objectModify = {
        id: idMenu,
        idapplication: watch("application"),
        idmenulocation: menuLocation,
        numberRows: menuLocation === 4 ? 4 : 0,
        menujson: JSON.stringify(removeTitle(valuesComplete)),
      };
      try {
        await new Promise(ModifyMenu(objectModify));
      } catch (error) {
        console.error(error);
      }
      setMessageChangeMenu(message);
      onOpen();
      setShouldUpdateMenu(true);
    }
    setIsSaveButton(false);
    setIsUpdatedItemFlag(false);
    menuSettingRef.current.addInitialDataMenu(valuesComplete);
    setCurrentMenu(valuesComplete);
    if (menuLocation === 1) setCurrentLeftDragDrop([]);
    if (menuLocation === 2) setCurrentTopDragDrop([]);
  };

  /**
   * Toma un array de objetos, y devuelve un array de objetos con la propiedad title eliminada de
   * cada objeto.
   * @returns Un array de objetos
   */
  const removeTitle = (menus) => {
    let objectMenus = [];
    if (menus !== undefined && menus.length > 0) {
      for (const menu of menus) {
        let objectMenuChildren = [];
        if (menu.children !== undefined && menu.children.length > 0) {
          objectMenuChildren = removeTitle(menu.children);
        }
        const { title, ...rest } = menu;
        if (objectMenuChildren.length !== 0) {
          objectMenus.push({ ...rest, children: objectMenuChildren });
        } else {
          objectMenus.push(rest);
        }
      }
    }
    return objectMenus;
  };

  //#endregion

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const renderPopup = () => {
    if (popupOpen) {
      return (
        <Dialog
          open={popupOpen}
          onClose={handleClosePopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {popup.messageType === "error" ? "Error" : "Notificación"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {popup.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePopup} color="primary" autoFocus>
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  };

  const cancelButton = () => {
    setIsSaveButton(false);
    setIsUpdatedItemFlag(false);
    onCancelButton(menuLocation);
  };

  return (
    <div style={{ width: "100%" }}>
      <div>
        <br/>
        <Grid container>
          <Grid item lg={8} md={12} sm={12} xs={12}>
            <ControlledSelect
              control={control}
              id="application"
              name="application"
              options={applications}
              label={"Aplicación"}
            />
          </Grid>
        </Grid>
        <MenuSettingsContainer
          saveMenu={saveMenu}
          applications={applications}
          setApplications={setApplications}
          currentApp={currentApp}
          setCurrentApp={setCurrentApp}
          setInitialFormValues={setInitialFormValues}
          menu={menu}
          MenuSelected={1}
          form={form}
          setOpen={setOpen}
          ref={menuSettingRef}
          menuLocation={menuLocation}
          applicationId={watch("application")}
          areChangesSave={isSaveButton}
          setChangesSave={setIsSaveButton}
          setCurrentLeftDragDrop={setCurrentLeftDragDrop}
          setCurrentTopDragDrop={setCurrentTopDragDrop}
          isUpdatedItemFlag={isUpdatedItemFlag}
          onCancelButton={cancelButton}
        />
        <ModalForm
          initialValues={initialFormValues}
          ref={modalRef}
          open={open}
          setOpen={setOpen}
          handleSave={saveItem}
          handleClose={handleClose}
          appName={currentApp}
          privileges={privilegesList}
        />
        <Dialog />
        <FullLoader open={loading} />
        {renderPopup()}
      </div>
    </div>
  );
});